import "./Ticket.css";
import { Link } from "react-router-dom";

import { returnStatusName } from "../../utils/utils";

const ActiveTicket = ({ ticketData }) => {
  return (
    <div className={`ticket ticket-revision`}>
      <h2>В момента се работи:</h2>
      <p>{ticketData.ticketTask}</p>
      <hr></hr>
      <p>
        <strong>Статус:</strong> {returnStatusName(ticketData.ticketStatus)}
      </p>
      <p>
        <strong>Контрагент: </strong>
        {ticketData.contragent}
      </p>

      <Link
        key={ticketData.cobjTicket}
        to={`/tickets/${ticketData.cobjTicketUser}-${ticketData.cobjTicket}`}
      >
        <button className="log-button btn btn-primary ticket-summary-button">
          Подробно
        </button>
      </Link>
    </div>
  );
};

export default ActiveTicket;
