import {
  useState,
  Fragment,
  useContext,
  useMemo,
  useEffect,
  useCallback,
} from "react";

import { UserContext } from "../contexts/UserContex";
import { useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import serverUrl from "../../config";

import "./TicketDetails.css";

import DetailsHeader from "./details/DetailsHeader";
import DetailsInput from "./details/DetailsInput";
import DetailsManageWork from "./details/DetailsManageWork";
import DetailsFinisedWork from "./details/DetailsFinishedWork";
import DetailsSecondLevelTicket from "./details/DetailsSecondLevelTicket";
import DetailsChangeTicketStatus from "./details/DetailsChangeTicketStatus";

const TicketDetails = ({ ticketUser }) => {
  const { userData, setStoreData, logOutHandler } = useContext(UserContext);
  const { id } = useParams();

  const [response, setResponse] = useState([]);

  const [isCompleted, setIsCompleted] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [canChangeStatus, setCanChangeStatus] = useState(false);

  const [completedWork, setCompletedWork] = useState([]);
  const [completedWorkJSX, setCompletedWorkJSX] = useState([]);

  const [secondLevelWork, setSecondLevelWork] = useState([]);
  const [secondLevelWorkJSX, setSecondLevelWorkJSX] = useState([]);

  const submitFormHandler = useCallback(
    (isSubmitted) => {
      setFormSubmitted(isSubmitted);
      // console.log(formSubmitted);
    },
    [formSubmitted]
  );

  const canChangeStatusHandler = (flag) => {
    setCanChangeStatus(flag);
  };

  const headers = useMemo(
    () => ({
      "Content-Type": "text/plain",
      Authorization: `Bearer ${userData.userAccToken}`,
    }),
    [userData.userAccToken]
  );

  const requestData = {
    nUser: userData.userId,
    nTicketUser: id?.split("-")[0],
    cobjTicket: id?.split("-")[1],
  };
  const data = JSON.stringify(requestData);

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const response = await axios.post(serverUrl + "/ticket/getData", data, {
          headers,
        });

        // console.log(response.data);

        setResponse(response.data);

        setCompletedWork(response.data.ticket.otcheti);
        setSecondLevelWork(response.data.ticket.helpers);

        switch (response?.data?.ticket?.ticketStatus) {
          case 1: // Очаква приемане
            setIsCompleted(true);
            break;

          case 2: // Приет за изпълнение
            setIsCompleted(false);
            break;

          case 3: // Изпълнен - За одобрение
            setIsCompleted(true);
            break;

          case 11: // Изпълнен - за приемане
            setIsCompleted(true);
            break;

          case 12: // Прекратен тикет
            setIsCompleted(true);
            break;
          case 13: // Отказан за изпълнение
            setIsCompleted(true);
            break;
          default:
            break;
        }
      } catch (error) {
        logOutHandler();
      } finally {
        submitFormHandler(false);
      }
    };

    fetchTicket();

    const intervalId = setInterval(() => {
      fetchTicket();
    }, 10000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [data, headers, setStoreData, submitFormHandler]);

  useEffect(() => {
    if (completedWork.length > 0) {
      setCompletedWorkJSX(
        completedWork.map((completed) => (
          <DetailsFinisedWork
            completedWork={completed}
            key={completed.otchetID}
          />
        ))
      );
    } else {
      setCompletedWorkJSX(<p>Все още не е отчетена работа по този тикет.</p>);
    }
  }, [completedWork]);

  useEffect(() => {
    if (secondLevelWork.length > 0) {
      setSecondLevelWorkJSX(
        secondLevelWork.map((work) => (
          <DetailsSecondLevelTicket
            work={work}
            key={work.nHelperRow}
            submitFormHandler={submitFormHandler}
          />
        ))
      );
    } else {
      setSecondLevelWorkJSX(<p>Все още не е отчетена работа по този тикет.</p>);
    }
  }, [secondLevelWork, submitFormHandler]);

  return (
    <Fragment>
      <DetailsHeader
        completedWork={completedWorkJSX}
        response={response}
        canChangeStatusHandler={canChangeStatusHandler}
        submitFormHandler={submitFormHandler}
      />

      {userData.userWorkerType === 1 ? (
        <>
          <DetailsManageWork helpersWork={secondLevelWorkJSX} />
        </>
      ) : (
        <>
          {secondLevelWork.length > 0 && response?.ticket.ticketStatus !== 1 ? (
            <DetailsManageWork helpersWork={secondLevelWorkJSX} />
          ) : null}

          {isCompleted ? null : (
            <Fragment>
              <hr></hr>
              <DetailsInput
                submitFormHandler={submitFormHandler}
                submitStatus={formSubmitted}
                response={response}
              />
            </Fragment>
          )}

          {canChangeStatus ? (
            <DetailsChangeTicketStatus
              response={response}
              submitFormHandler={submitFormHandler}
            />
          ) : null}

          <ToastContainer
            position="bottom-center"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
      )}
    </Fragment>
  );
};

export default TicketDetails;
