import Ticket from "./Ticket";
import "./TicketList.css";
import { useContext } from "react";

import { UserContext } from "../contexts/UserContex";

const TicketsList = () => {
  const { tickets } = useContext(UserContext);

  return (
    <div className="ticket-list">
      {tickets?.length > 0 ? (
        tickets?.map((ticket) => (
          <Ticket
            ticketData={ticket}
            key={`${ticket.cobjTicketUser}-${ticket.cobjTicket}`}
          />
        ))
      ) : (
        <div className="ticket">
          <div>Няма тикети от този вид.</div>
        </div>
      )}
    </div>
  );
};

export default TicketsList;
