import "./DetailsFinishedWork.css";
import "../Ticket.css";

const DetailsFinisedWork = ({ completedWork }) => {
  let ticketStatusStyle = "";

  if (completedWork.otchetCharacter === 4) {
    ticketStatusStyle = "ticket-finished ticket";
  } else {
    ticketStatusStyle = "finished-work-d";
  }

  return (
    <div className={ticketStatusStyle}>
      <p>
        <strong>извършена дейност:</strong> {completedWork.otchetText}
      </p>
      <p>
        <strong>дата: </strong>
        {completedWork.otchetDate}
      </p>

      {completedWork.otchetTime !== "0 мин." ? (
        <p>
          <strong>работено в мин.:</strong> {completedWork.otchetTime}
        </p>
      ) : null}
    </div>
  );
};

export default DetailsFinisedWork;
