import { Button } from "react-bootstrap";
import { returnStatusName } from "../../../utils/utils";
import { useState, useEffect, useContext } from "react";
import "./DetailsSecondLevelTicket.css";
import { Accordion } from "react-bootstrap";
import DetailsFinisedWork from "./DetailsFinishedWork";

import axios from "axios";
import serverUrl from "../../../config";

import { UserContext } from "../../contexts/UserContex";
import { useParams } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DetailsSecondLevelTicket = ({ work, submitFormHandler }) => {
  const { userData } = useContext(UserContext);
  const statusName = returnStatusName(work.helperTaskStatus);
  const { id } = useParams();
  const [waitingConfirm, setWaitingConfirm] = useState(false);
  const [waitingTerminate, setWaitingTerminate] = useState(false);
  const [ticketStyle, setTicketStyle] = useState("");
  const [completedWorkJSX, setCompletedWorkJSX] = useState([]);

  useEffect(() => {
    switch (work.helperTaskStatus) {
      case 1:
        setWaitingTerminate(true);
        setWaitingConfirm(false);
        setTicketStyle("ticket ticket-await-accept");
        break;
      case 2:
        setWaitingTerminate(true);
        setWaitingConfirm(false);
        setTicketStyle("ticket ticket-active");
        break;
      case 3: // Изпълнен - За одобрение
        setWaitingConfirm(true);
        setWaitingTerminate(false);
        setTicketStyle("ticket ticket-revision");
        break;
      case 11:
        setWaitingConfirm(false);
        setWaitingTerminate(false);
        setTicketStyle("ticket ticket-finished");
        break;
      case 12:
        setWaitingConfirm(false);
        setWaitingTerminate(false);
        setTicketStyle("ticket ticket-terminated");
        break;
      case 13:
        setWaitingConfirm(false);
        setWaitingTerminate(false);
        setTicketStyle("ticket ticket-terminated");
        break;

      default:
        setWaitingConfirm(false);
        setWaitingTerminate(false);
        break;
    }
  }, [work.helperTaskStatus]);

  useEffect(() => {
    if (work.helperOtchets.length > 0) {
      setCompletedWorkJSX(
        work.helperOtchets.map((completed) => (
          <DetailsFinisedWork
            completedWork={completed}
            key={completed.otchetID}
          />
        ))
      );
    } else {
      setCompletedWorkJSX(<p>Все още не е отчетена работа от помощника.</p>);
    }
  }, [work.helperOtchets]);

  const handleWorkStatus = (newStatus) => {
    let confirmMessage = "";
    // let newTicketWorkerStatus = 0;

    switch (newStatus) {
      case 11:
        confirmMessage =
          "Сигурни ли сте, че искате да приемете работата извършена от работника?";

        // newTicketWorkerStatus = 0;
        break;

      case 2:
        confirmMessage =
          "Сигурни ли сте, че искате да върнете работата за довършване от работника?";
        // newTicketWorkerStatus = 0;
        break;
      case 12:
        confirmMessage =
          "Сигурни ли сте, че искате да прекратите работата на работника?";
        // newTicketWorkerStatus = 0;
        break;
      default:
        confirmMessage = "Възникна грешка.";
        // newTicketWorkerStatus = 0;
        break;
    }

    confirmAlert({
      title: "Потвърдете",
      message: confirmMessage,
      buttons: [
        {
          label: "Да",
          onClick: async () => {
            const requestData = {
              nUser: id?.split("-")[0],
              nUserTicket: id?.split("-")[1],
              nHelperNewStatus: newStatus,
              nHelperRow: work.nHelperRow,
            };

            JSON.stringify(requestData);
            const data = requestData;

            const headers = {
              "Content-Type": "text/plain; charset=utf-8",
              Authorization: `Bearer ${userData.userAccToken}`,
            };

            try {
              const response = await axios.post(
                serverUrl + "/ticket/changeHelperStatus",
                data,
                {
                  headers,
                }
              );

              // console.log(response.data);
              submitFormHandler(true);

              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } catch (error) {
              // console.log(`Error: ${error}`);
            }
          },
        },
        {
          label: "Не",
          onClick: () => {
            // Handle the rejection or perform any other action
            // console.log("Form submission canceled");
          },
        },
      ],
    });
  };

  return (
    <div className={`finished-work ${ticketStyle}`}>
      <p>
        <strong>Помощник:</strong> {work.nHelperName}
      </p>
      <hr></hr>

      <p>
        <strong>Задача:</strong> {work.helperTask}
      </p>
      <p>
        <strong>Крайна дата:</strong> {work.helperTaskEndDate}
      </p>
      <hr></hr>
      <p>
        <strong>Общо работено време:</strong> {work.helperTaskSumTime}
      </p>
      <p>
        <strong>Статус:</strong> {statusName}
      </p>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <p>Покажи работа на помощници</p>
          </Accordion.Header>
          <Accordion.Body>{completedWorkJSX}</Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {userData.userWorkerType === 0 && (
        <>
          {waitingTerminate ? (
            <div className="button-container">
              <hr></hr>
              <Button onClick={() => handleWorkStatus(12)} variant="danger">
                Прекрати
              </Button>
            </div>
          ) : null}

          {waitingConfirm ? (
            <div className="button-container">
              <hr></hr>
              <Button onClick={() => handleWorkStatus(11)}>Приключи</Button>
              <Button onClick={() => handleWorkStatus(2)} variant="warning">
                Върни за изпълнение
              </Button>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default DetailsSecondLevelTicket;
