import { Accordion } from "react-bootstrap";

const DetailsManageWork = ({ helpersWork }) => {
  return (
    <div className="ticket-details log-in-form">
      <h4>Извършена работа от помощници:</h4>
      <hr></hr>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <p>Покажи работа на помощници</p>
          </Accordion.Header>
          <Accordion.Body>{helpersWork}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default DetailsManageWork;
