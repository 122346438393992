import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import LogInForm from "./components/log-in/LogInForm";
import Home from "./pages/Home";

// BrowserRouter
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "./components/contexts/UserContex";
import TicketDetails from "./components/tickets/TicketDetails";
import MainMenu from "./components/navigation/Navigation";
import UserPage from "./pages/UserPage";

function App() {
  const { userData } = useContext(UserContext);

  return (
    <Router>
      {userData.isLogged ? <MainMenu></MainMenu> : null}
      <div className="app-wrapper">
        {userData.isLogged ? (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/userProfile" element={<UserPage />} />
            <Route path="/tickets/:id" element={<TicketDetails />} />
          </Routes>
        ) : (
          <LogInForm />
        )}
      </div>
    </Router>
  );
}

export default App;
