import React, { createContext, useState } from "react";
import axios from "axios";
import serverUrl from "../../config";

// Create the UserContext
export const UserContext = createContext();

export const DefaultStoreState = {
  user: {
    isLogged: false,
    name: null,
    userId: null,
    filterValue: null,
    userBAZAWorkerType: null,
    userWorkerType: null,
    userType: null,
    userAccToken: null,
    userRefreshToken: null,
    expTime: null,
  },
  tickets: [],
  active: {},
};

export const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjEpirationTime = new Date(expirationTime).getTime();
  const remainingDuration = adjEpirationTime - currentTime;

  return remainingDuration;
};

const loadLocalStore = () => {
  let isLoggedLocalStorage = localStorage.getItem("isLogged");
  let userName = localStorage.getItem("userName");
  let userId = localStorage.getItem("userId");
  let filterValue = localStorage.getItem("filterValue");
  let userType = localStorage.getItem("userType");
  let userWorkerType = localStorage.getItem("userWorkerType");
  let userAccToken = localStorage.getItem("accToken");
  let userRefreshToken = localStorage.getItem("refreshToken");
  let expTime = localStorage.getItem("expTime");

  if (isLoggedLocalStorage == null || isLoggedLocalStorage === "false") {
    isLoggedLocalStorage = false;
  }

  if (userName == null) {
    userName = "";
  }

  const remainingTime = calculateRemainingTime(expTime);
  if (remainingTime < 0) {
    // window.location.href = "/";
    return {
      ...DefaultStoreState,
    };
  }

  return {
    ...DefaultStoreState,
    user: {
      isLogged: isLoggedLocalStorage,
      name: userName,
      userId: userId,
      filterValue: filterValue,
      userBAZAWorkerType: Number(userWorkerType),
      userWorkerType: Number(userWorkerType),
      userType: userType,
      userAccToken: userAccToken,
      userRefreshToken: userRefreshToken,
      expTime: expTime,
      remainingTime: remainingTime,
    },
  };
};

const updateLocalStore = (userData, filterValue) => {
  if (userData) {
    localStorage.setItem("isLogged", userData.isLogged);
    localStorage.setItem("userName", userData.name);
    localStorage.setItem("userId", userData.userId);
    localStorage.setItem("userType", userData.userType);
    localStorage.setItem("userWorkerType", userData.userWorkerType);
    localStorage.setItem("accToken", userData.userAccToken);
    localStorage.setItem("refreshToken", userData.userRefreshToken);
    localStorage.setItem("expTime", userData.expTime);
    localStorage.setItem("expTime", userData.workerType);
  }

  if (filterValue) {
    localStorage.setItem("filterValue", filterValue);
  }

  if (userData === null && filterValue === null) {
    localStorage.removeItem("isLogged");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("userType");
    localStorage.removeItem("userWorkerType");
    localStorage.removeItem("accToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expTime");
    localStorage.removeItem("filterValue");
  }
};

// Create the UserProvider component
export const UserProvider = ({ children }) => {
  const [storeData, setStoreData] = useState(loadLocalStore);

  const userLogIn = async (username, password) => {
    try {
      const response = await axios.post(
        serverUrl + "/account/login",
        {},
        {
          auth: {
            username: username,
            password: password,
          },
        }
      );

      // console.log(response.data);

      if (response.status !== 200) {
        return {
          error: true,
          statuscode: response.status,
          errorMessage: response.data.error,
        };
      }

      let userType = "";
      response.data.userType === 1 ? (userType = "ФЛ") : (userType = "ЮЛ");
      let userFullName = `${response.data.userFirstName} ${response.data.userSecondName} ${response.data.userLastName}`;

      const expirationTime = new Date(
        new Date().getTime() + +response.data.expiresIn * 1000
      );
      const remainingTime = calculateRemainingTime(expirationTime);

      let updatedUserData = {
        name: userFullName,
        userType: userType,
        isLogged: true,
        filterValue: 1,
        userId: response.data.nUser,
        userBAZAWorkerType: Number(response.data.userWorkerType),
        userWorkerType: Number(response.data.userWorkerType),
        userAccToken: response.data.accToken,
        userRefreshToken: response.data.refreshToken,
        expTime: expirationTime.toISOString(),
        remainingTime: remainingTime,
      };

      setStoreData(() => ({
        user: updatedUserData,
      }));

      updateLocalStore(updatedUserData, 1);
    } catch (error) {
      return {
        error: true,
        errorMessage: error.message,
      };
    }
  };

  const userLogOut = () => {
    updateLocalStore(null, null);
    setStoreData(loadLocalStore);
  };

  return (
    <UserContext.Provider
      value={{
        userData: storeData.user,
        tickets: storeData.tickets,
        setStoreData,
        updateLocalStore: updateLocalStore,
        logInHandler: userLogIn,
        logOutHandler: userLogOut,
        active: storeData.active,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
