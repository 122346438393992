import TicketsList from "../components/tickets/TicketsList";
import TicketFilter from "../components/tickets/TicketFilter";
import Pagination from "../components/pagination/Pagination";
import { useState, useCallback } from "react";

import { useContext } from "react";
import { UserContext } from "../components/contexts/UserContex";

import ActiveTicket from "../components/tickets/ActiveTicket";

const Home = () => {
  const [activePage, setActivePage] = useState(1);
  const [ticketsCount, setTicketsCount] = useState(0);
  const [filterFirstTime, setFilterFirstTime] = useState(true);

  const { active } = useContext(UserContext);

  const activePageHandler = (chosePage) => {
    setActivePage(chosePage);
    window.scrollTo(0, 0);
  };

  const ticketsCountHandler = useCallback((tickets) => {
    setTicketsCount(tickets);
  }, []);

  const filterFirstTimeHandler = (flag) => {
    setFilterFirstTime(flag);
  };

  return (
    <div>
      {active?.cobjTicket > 0 ? <ActiveTicket ticketData={active} /> : null}
      <TicketFilter
        activePage={activePage}
        pageHandler={activePageHandler}
        ticketsCountHandler={ticketsCountHandler}
        filterFirstTime={filterFirstTime}
        filterFirstTimeHandler={filterFirstTimeHandler}
      />
      <TicketsList />

      <Pagination
        pageHandler={activePageHandler}
        activePage={activePage}
        ticketsCount={ticketsCount}
        filterFirstTimeHandler={filterFirstTimeHandler}
      />
    </div>
  );
};

export default Home;
