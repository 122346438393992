import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { UserContext } from "../../contexts/UserContex";

import { Form, Button } from "react-bootstrap";

import axios from "axios";
import serverUrl from "../../../config";

import TicketStartFinishWork from "./DetailsStartFinishWork";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./DetailsInput.css";

const DetailsInput = ({ submitFormHandler, response }) => {
  const { userData } = useContext(UserContext);

  const [isComplete, setIsComplete] = useState(false);

  const [workedTime, setWorkedTime] = useState("");
  const [work, setWork] = useState("");
  const [characteristics, setCharacteristics] = useState(-1);

  const [areInputsVisible, setAreInputsVisible] = useState(false);
  const [inputsButtonText, setInputsButtonText] = useState(
    "Покажи полета за друг отчет"
  );

  const toggleInputsVisibility = () => {
    setAreInputsVisible(!areInputsVisible);

    if (areInputsVisible) {
      setInputsButtonText("Покажи полетата за друг отчет");
    } else {
      setInputsButtonText("Скрий полетата");
    }
  };

  const handleCharacteristicsChange = (event) => {
    setCharacteristics(event.target.value);
  };

  const { id } = useParams();

  const workHandler = (statusOfWork) => {
    if (statusOfWork) {
      confirmAlert({
        title: "Потвърдете",
        message: "Започването на работа",
        buttons: [
          {
            label: "Да",
            onClick: async () => {
              const requestData = {
                nUser: id?.split("-")[0],
                cobjTicket: id?.split("-")[1],
                otchetText: "Започната работа.",
                otchetCharacter: 3,
              };

              JSON.stringify(requestData);
              const data = requestData;

              const headers = {
                "Content-Type": "text/plain; charset=utf-8",
                Authorization: `Bearer ${userData.userAccToken}`,
              };

              try {
                const response = await axios.post(
                  serverUrl + "/ticket/postOtchet",
                  data,
                  {
                    headers,
                  }
                );

                // console.log(response);

                submitFormHandler(true);

                if (response.data.error !== undefined) {
                  toast.error(response.data.error, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                } else {
                  toast.success(response.data.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              } catch (error) {}
            },
          },
          {
            label: "Не",
            onClick: () => {
              // Handle the rejection or perform any other action
              // console.log("Form submission canceled");
            },
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Потвърдете",
        message: "Приключването на работа",
        buttons: [
          {
            label: "Да",
            onClick: async () => {
              // function excelDateToJavaScriptDate(excelDate) {
              //   const millisecondsSince1900 =
              //     (excelDate - 25569) * 86400 * 1000;
              //   return new Date(millisecondsSince1900);
              // }

              function ExcelDateToJSDate(serial) {
                var utc_days = Math.floor(serial - 25569);
                var utc_value = utc_days * 86400;
                var date_info = new Date(utc_value * 1000);

                var fractional_day = serial - Math.floor(serial) + 0.0000001;

                var total_seconds = Math.floor(86400 * fractional_day);

                var seconds = total_seconds % 60;

                total_seconds -= seconds;

                var hours = Math.floor(total_seconds / (60 * 60));
                var minutes = Math.floor(total_seconds / 60) % 60;

                return new Date(
                  date_info.getFullYear(),
                  date_info.getMonth(),
                  date_info.getDate(),
                  hours,
                  minutes,
                  seconds
                );
              }

              const excelDate = response?.ticket.ticketStartWorkDate;
              const dateObject = ExcelDateToJSDate(excelDate);

              const currentDate = new Date();
              const timeDifferenceInMilliseconds = currentDate - dateObject;

              // Convert the difference to other units if needed
              // const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
              // const timeDifferenceInDays = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);
              // const timeDifferenceInHours =
              //   timeDifferenceInMilliseconds / (1000 * 60 * 60);

              let timeDifferenceInMinutes =
                timeDifferenceInMilliseconds / (1000 * 60);
              if (timeDifferenceInMinutes > 480) {
                timeDifferenceInMinutes = 480;
              }

              const requestData = {
                nUser: id?.split("-")[0],
                cobjTicket: id?.split("-")[1],
                otchetText: "Приключена работа.",
                otchetTime: timeDifferenceInMinutes,
                otchetCharacter: 4,
              };

              JSON.stringify(requestData);
              const data = requestData;

              const headers = {
                "Content-Type": "text/plain; charset=utf-8",
                Authorization: `Bearer ${userData.userAccToken}`,
              };

              try {
                const response = await axios.post(
                  serverUrl + "/ticket/postOtchet",
                  data,
                  {
                    headers,
                  }
                );

                // console.log(response.data);

                submitFormHandler(true);

                if (response.data.error !== undefined) {
                  toast.error(response.data.error, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                } else {
                  toast.success(response.data.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              } catch (error) {}
            },
          },
          {
            label: "Не",
            onClick: () => {
              // Handle the rejection or perform any other action
              // console.log("Form submission canceled");
            },
          },
        ],
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let confirmMessage = "";

    if (isComplete) {
      confirmMessage =
        "Сигурни ли сте, че сте въвели всички данни правилно? Този тикет ще бъде приключен и няма да може да бъде отчитана повече работа по него.";
    } else {
      confirmMessage = "Сигурни ли сте, че сте въвели всички данни правилно?";
    }

    confirmAlert({
      title: "Потвърдете",
      message: confirmMessage,
      buttons: [
        {
          label: "Да",
          onClick: async () => {
            const requestData = {
              nUser: id?.split("-")[0],
              cobjTicket: id?.split("-")[1],
              otchetText: work,
              otchetTime: workedTime,
              otchetCharacter: characteristics,
            };

            JSON.stringify(requestData);
            const data = requestData;

            const headers = {
              "Content-Type": "text/plain; charset=utf-8",
              Authorization: `Bearer ${userData.userAccToken}`,
            };

            try {
              const response = await axios.post(
                serverUrl + "/ticket/postOtchet",
                data,
                {
                  headers,
                }
              );

              // console.log(response.data);

              setWork("");
              setIsComplete(false);
              setWorkedTime(0);
              submitFormHandler(true);

              toast.success("Успешно изпратен.", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } catch (error) {}
          },
        },
        {
          label: "Не",
          onClick: () => {
            // Handle the rejection or perform any other action
            // console.log("Form submission canceled");
          },
        },
      ],
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="log-in-form ticket-details">
      <TicketStartFinishWork workHandler={workHandler} response={response} />
      <hr></hr>

      {response?.ticket.ticketStartWorkDate !== "" && (
        <div>
          <Button
            onClick={toggleInputsVisibility}
            className="buttom-visibility-inputs"
          >
            {inputsButtonText}
          </Button>
          {areInputsVisible ? (
            <>
              <p>Извършена работа:</p>
              <textarea
                value={work}
                onChange={(event) => setWork(event.target.value)}
                required
                minLength={1}
                className="textarea-ticket"
              ></textarea>
              <Form.Group>
                <Form.Label>Работено време(мин.)</Form.Label>
                <Form.Control
                  className="ticket-details-input"
                  type="number"
                  value={workedTime}
                  onChange={(event) => setWorkedTime(event.target.value)}
                  min={0}
                  max={9999}
                />
              </Form.Group>
              <p>Характериситка:</p>
              <Form.Select
                aria-label="Default select example"
                value={characteristics}
                onChange={handleCharacteristicsChange}
              >
                <option disabled defaultValue={0} value="-1">
                  - Изберете характеристика -
                </option>
                <option value="0">Описание</option>
                <option value="1">Важно</option>
                <option value="2">Опит</option>
              </Form.Select>
              <hr></hr>
              <Button
                variant="primary"
                type="submit"
                className="log-button buttom-change-status"
              >
                Изпрати
              </Button>{" "}
            </>
          ) : null}
        </div>
      )}
    </Form>
  );
};

export default DetailsInput;
