import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

import { Link } from "react-router-dom";
import { useRef, useContext } from "react";
import { UserContext } from "../contexts/UserContex";

import "./Navigation.css";

function MainMenu() {
  const { userData } = useContext(UserContext);
  const offCanvasRef = useRef();
  const closeOffCanvas = () => {
    offCanvasRef.current.backdrop?.click();
  };

  return (
    <div className="heder-sticky">
      {["xxl"].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Brand as={Link} to="">
              <img
                src="https://assets.jobs.bg/assets/logo/2018-12-12/b_8ef1fa87a56fcf3c9e469a7b057f8711.jpg"
                alt="nola7"
                width={100}
              ></img>
            </Navbar.Brand>
            {userData.userWorkerType === 1 && (
              <div className="container-user-role">
                <h5 className="user-type-text">Project Manager</h5>
              </div>
            )}

            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              ref={offCanvasRef}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Меню
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 ">
                  <Nav.Link
                    as={Link}
                    to=""
                    onClick={closeOffCanvas}
                    className="mobile-menu-item menu-nav"
                  >
                    Работа с тикети
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="userProfile"
                    onClick={closeOffCanvas}
                    className="mobile-menu-item menu-nav"
                  >
                    Потребителски профил
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </div>
  );
}

export default MainMenu;
