import "./UserProfile.css";
import { Form } from "react-bootstrap";
import { useContext, useCallback } from "react";
import { UserContext } from "../contexts/UserContex";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import serverUrl from "../../config";

let logoutTimer;

const UserProfile = () => {
  const { userData, logOutHandler, updateLocalStore, setStoreData } =
    useContext(UserContext);
  const [hourStatistics, sethourStatistics] = useState();
  const [typeWorker, setTypeWorker] = useState(userData.userWorkerType || 0);

  const headers = useMemo(
    () => ({
      "Content-Type": "text/plain",
      Authorization: `Bearer ${userData.userAccToken}`,
    }),
    [userData.userAccToken]
  );

  const handleTypeChange = (event) => {
    if (event.target.value > 1) {
      event.target.value = 0;
    }

    userData.userWorkerType = Number(event.target.value);
    setTypeWorker(Number(event.target.value));
    updateLocalStore(userData, 1);

    setStoreData((prevStoreData) => ({
      ...prevStoreData,
      userData,
    }));

    setTypeWorker();
  };

  useEffect(() => {
    const fetchHourStatistics = async () => {
      try {
        const requestType = "/hoursStatistic";

        const requestData = {
          nUser: userData.userId,
          nUserWorkerType: userData.userWorkerType,
        };

        const data = JSON.stringify(requestData);
        const response = await axios.post(serverUrl + requestType, data, {
          headers,
        });

        sethourStatistics(...response.data.hours);
      } catch (error) {
        logOutHandler();
      }
    };

    fetchHourStatistics();

    const intervalId = setInterval(() => {
      fetchHourStatistics();
    }, 20000);

    return () => {
      clearInterval(intervalId);
    };
  }, [headers, userData?.userId, userData?.userWorkerType]);

  const handleLogOut = useCallback(() => {
    logOutHandler();
    clearTimeout(logoutTimer);
    window.location.href = "/";
  }, [logOutHandler]);

  return (
    <div className="user-profile">
      <p>
        Име:
        <strong> {userData.name}</strong>
      </p>
      <hr></hr>

      {userData.userBAZAWorkerType === 1 && (
        <>
          <Form.Select
            aria-label="Default select example"
            onChange={handleTypeChange}
            value={typeWorker}
            className="user-type-select"
          >
            <option value="0">Изпълнител</option>
            <option value="1">Project Manager</option>
          </Form.Select>
          <hr></hr>
        </>
      )}

      <div className="user-data">
        <button className="btn btn-primary " onClick={handleLogOut}>
          Изход
        </button>
      </div>
      <hr></hr>

      {userData.userWorkerType === 0 ? (
        <>
          <p>
            <strong>
              <u>Рекапитуация на часовете по статус на тикета</u>
            </strong>
          </p>
          {hourStatistics?.OldConfirmedHours && (
            <p>
              Признати часове до {hourStatistics?.OldConfirmedHoursDate}:{" "}
              <strong>
                {" "}
                <u>{hourStatistics?.OldConfirmedHours}</u>
              </strong>
            </p>
          )}

          <p>
            Отработени непризнати:{" "}
            <strong> {hourStatistics?.finishedNotConfirmedHours}</strong>
          </p>
          <p>
            Отработени признати:{" "}
            <strong> {hourStatistics?.finishedHours}</strong>
          </p>
          <p>
            1. от началото на годината:{" "}
            <strong> {hourStatistics?.finishedHoursFromBegYear}</strong>
          </p>
          <p>
            2. от началото на месеца:{" "}
            <strong> {hourStatistics?.finishedHoursFromBegMonth}</strong>
          </p>
          <hr></hr>
          <p>
            Бъдещи приети:{" "}
            <strong>{hourStatistics?.futureHoursAccepted}</strong>
          </p>
          <p>
            Бъдещи неприети:{" "}
            <strong>{hourStatistics?.futureHoursNotAccepted}</strong>
          </p>
        </>
      ) : (
        <>
          <p>
            <strong>
              <u>
                Рекапитуация на часовете по статус на тикетите на изпълнителите
              </u>
            </strong>
          </p>
          <p>
            1. Неприети: <strong>{hourStatistics?.notAcceptedHours}</strong>
          </p>
          <p>
            2. Приети за изпълнение:{" "}
            <strong>{hourStatistics?.AcceptedHours}</strong>
          </p>
          <p>
            3. Приключени - непотвърдени:{" "}
            <strong>{hourStatistics?.finishedNotConfirmedHours}</strong>
          </p>
          <p>
            4. Приключени - потвърдени:{" "}
            <strong>{hourStatistics?.finishedConfirmedHours}</strong>
          </p>
          <p>
            5. Прекратени / Отказани:{" "}
            <strong>{hourStatistics?.stopedOrRejectedHours}</strong>
          </p>
        </>
      )}
    </div>
  );
};

export default UserProfile;
