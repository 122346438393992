import "./TicketFilter.css";
import { Form } from "react-bootstrap";
import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../contexts/UserContex";

import axios from "axios";
import serverUrl from "../../config";

const TicketFilter = ({
  activePage,
  pageHandler,
  ticketsCountHandler,
  filterFirstTime,
  filterFirstTimeHandler,
}) => {
  const { userData, setStoreData, updateLocalStore, logOutHandler } =
    useContext(UserContext);

  const [selectedOption, setSelectedOption] = useState(
    userData.filterValue || "1"
  );

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);

    filterFirstTimeHandler(true);
    pageHandler(1);

    updateLocalStore(null, event.target.value);
    userData.filterValue = event.target.value;

    setStoreData((prevStoreData) => ({
      ...prevStoreData,
      userData,
    }));
  };

  const headers = useMemo(
    () => ({
      "Content-Type": "text/plain",
      Authorization: `Bearer ${userData.userAccToken}`,
    }),
    [userData.userAccToken]
  );

  useEffect(() => {
    const fetchActiveTickets = async () => {
      try {
        const requestType = "/returnTickets";
        const filterVal = userData.filterValue;
        let pageInd = 0;

        if (filterFirstTime) {
          pageInd = 1;
        } else {
          pageInd = activePage;
        }

        const requestData = {
          nUser: userData.userId,
          nPage: pageInd,
          nFilterType: filterVal,
          nUserWorkerType: userData.userWorkerType,
        };

        // console.log(requestData);

        const data = JSON.stringify(requestData);
        const response = await axios.post(serverUrl + requestType, data, {
          headers,
        });

        ticketsCountHandler(response.data.brTickets);

        setStoreData((prevUserData) => ({
          ...prevUserData,
          tickets: response.data.tickets,
          active: response.data.activeTicket,
        }));
      } catch (error) {
        logOutHandler();
      }
    };

    fetchActiveTickets();

    const intervalId = setInterval(() => {
      fetchActiveTickets();
    }, 10000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [
    headers,
    setStoreData,
    userData?.tickets?.length,
    userData?.filterValue,
    userData?.userWorkerType,
    userData?.userId,
    activePage,
    ticketsCountHandler,
    filterFirstTime,
  ]);

  return (
    <div className="ticket-filter">
      <h3>
        <strong>Филтър:</strong>
      </h3>
      <Form.Select
        aria-label="Default select example"
        onChange={handleSelectChange}
        value={selectedOption}
      >
        {userData.userWorkerType === 0 ? (
          <>
            <option value="1">Очаква приемане</option>
            <option value="2">Приет за изпълнение</option>
            <option value="3">Изпълнен - За одобрение</option>
            <option value="11">Приключени тикети</option>
            <option value="12">Прекратени / Отказани</option>
            <option value="99">Всички</option>
          </>
        ) : (
          <>
            <option value="1">Неприети</option>
            <option value="2">Приет за изпълнение</option>
            <option value="11">Приключени - непотвърдени</option>
            <option value="111">Приключени - потвърдени</option>
            <option value="12">Прекратени / Отказани</option>
            <option value="99">Всички</option>
          </>
        )}
      </Form.Select>
    </div>
  );
};

export default TicketFilter;
