import { Form, Button, InputGroup } from "react-bootstrap";
import { useState } from "react";
import "./LogInForm.css";

import { useContext } from "react";
import { UserContext } from "../contexts/UserContex";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjEpirationTime = new Date(expirationTime).getTime();
  const remainingDuration = adjEpirationTime - currentTime;

  return remainingDuration;
};

const LogInForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const { logInHandler } = useContext(UserContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (username === "" || password === "") {
      return;
    }

    const responseLogIn = logInHandler(username, password);

    if (responseLogIn?.error) {
      toast.error(responseLogIn.errorMessage, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    setUsername("");
    setPassword("");
  };

  return (
    <div>
      <img
        src="https://assets.jobs.bg/assets/logo/2018-12-12/b_8ef1fa87a56fcf3c9e469a7b057f8711.jpg" //"https://www.ejsystem.bg/wp-content/uploads/2023/07/logo-icons-for-nav-bar-02.png"
        alt="logo"
        className="logo-log"
      ></img>
      <Form
        onSubmit={handleSubmit}
        validated={validated}
        className="log-in-form"
        noValidate
      >
        <Form.Group controlId="formUsername" className="input-login">
          <Form.Label>Потребителско име</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              placeholder="Въведете потребителско име"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              // isInvalid={
              //   !(username.length > 6) && (username.length || validated)
              // }
              minLength={5}
              required
              aria-describedby="inputGroupPrepend"
              isValid={false}
            />
            <Form.Control.Feedback type="invalid">
              Потребителското име е поне 5 символа.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="formPassword" className="input-login">
          <Form.Label>Парола</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="password"
              placeholder="Парола"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              // isInvalid={
              //   !(password.length > 8 || validated) &&
              //   (password.length || validated)
              // }
              minLength={8}
              maxLength={30}
              required
            />
            <Form.Control.Feedback type="invalid">
              Паролата е между 8 и 30 символа.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <div className="input-login-button">
          <Button variant="primary" type="submit" className="log-button ">
            Вход
          </Button>
        </div>
      </Form>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default LogInForm;
