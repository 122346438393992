import "./Ticket.css";
import { Link } from "react-router-dom";
import { Fragment, useContext } from "react";
import { UserContext } from "../contexts/UserContex";
import { returnStatusName } from "../../utils/utils";

const Ticket = ({ ticketData }) => {
  const { userData } = useContext(UserContext);

  let ticketStyle = "";

  switch (ticketData.ticketStatus) {
    case 1: // Очаква приемане
      ticketStyle = "ticket ticket-await-accept";
      break;

    case 2: // Приет за изпълнение
      ticketStyle = "ticket ticket-active";
      break;

    case 3: // Изпълнен - За одобрение
      ticketStyle = "ticket ticket-revision";
      break;

    case 11: // Приключен тикет
      ticketStyle = "ticket  ticket-finished";
      break;

    case 12: // Прекратен тикет
      ticketStyle = "ticket ticket-terminated";
      break;

    case 13: // Отказан за изпълнение
      ticketStyle = "ticket ticket-terminated";

      break;

    default:
      ticketStyle = "ticket ticket-terminated";
      break;
  }

  return (
    <div className={ticketStyle}>
      <h2>{ticketData.ticketTask}</h2>
      <hr></hr>
      {userData.userWorkerType === 1 && (
        <u>
          <p>
            <strong>Ръководител: </strong>
            {ticketData.ticketUserName}
          </p>
        </u>
      )}
      <p>
        <strong>Статус: </strong>
        {returnStatusName(ticketData.ticketStatus)}
      </p>
      <hr></hr>

      <p>
        <strong>Контрагент: </strong>
        {ticketData.contragent}
      </p>

      <p>
        <strong>Начална дата: </strong> {ticketData.ticketBegDate}
      </p>
      <p>
        <strong>Краен срок: </strong> {ticketData.ticketEndDate}
      </p>
      <p>
        <strong>
          {userData.userWorkerType === 0
            ? "Планирани часове: "
            : "Планирани часове на екипа: "}
        </strong>
        {ticketData.ticketPMSPlannedHours}
      </p>

      {ticketData?.ticketPriznatDate && (
        <p>
          <strong>Часовете на екипа са признати на: </strong>
          {ticketData?.ticketPriznatDate}
        </p>
      )}

      {ticketData.ticketHelpersAll > 0 ? (
        <>
          <hr></hr>
          <p>
            {userData.userWorkerType === 0 ? (
              <>
                <strong>Помощници: </strong>
                {ticketData.ticketHelpersAll}{" "}
              </>
            ) : (
              <>
                <strong>Брой хора в екипа: </strong>
                {ticketData.ticketHelpersAll + 1}{" "}
              </>
            )}
          </p>
        </>
      ) : null}

      {/* {ticketData.ticketHelpersWorking > 0 ? (
        <p>
          В момента работят: <strong>{ticketData.ticketHelpersWorking}</strong>
        </p>
      ) : null} */}

      {ticketData.ticketHelpersRevision > 0 ? (
        <p>
          Чакащи потвърждение:{" "}
          <strong>{ticketData.ticketHelpersRevision}</strong>
        </p>
      ) : null}

      <Link
        key={`${ticketData.cobjTicketUser}-${ticketData.cobjTicket}`}
        to={`/tickets/${ticketData.cobjTicketUser}-${ticketData.cobjTicket}`}
      >
        <button className="log-button btn btn-primary ticket-summary-button">
          Подробно
        </button>
      </Link>
    </div>
  );
};

export default Ticket;
