import { Button } from "react-bootstrap";

import "./Pagination.css";

const Pagination = ({
  pageHandler,
  ticketsCount,
  activePage,
  filterFirstTimeHandler,
}) => {
  const perPage = 10;
  const totalPages = Math.ceil(ticketsCount / perPage);

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const pageClickHandler = (event) => {
    filterFirstTimeHandler(false);
    pageHandler(event.target.innerText);
  };

  return (
    <>
      <hr></hr>
      <div className="pagination-wrapper">
        {totalPages > 1
          ? pageNumbers.map((page) => (
              <Button
                key={page}
                onClick={pageClickHandler}
                className="pagination-button"
                variant={page === +activePage ? "primary" : "outline-secondary"}
              >
                {page}
              </Button>
            ))
          : null}
      </div>
    </>
  );
};

export default Pagination;
