import { Button } from "react-bootstrap";

import "./DetailsStartFinishWork.css";

const DetailsStartFinishWork = ({ workHandler, response }) => {
  return (
    <div className="button-wrapper">
      {response?.ticket.ticketStartWorkDate === "" ? (
        <Button
          onClick={() => workHandler(true)}
          className="buton-start-finish-work"
        >
          Започвам работа на обекта
        </Button>
      ) : (
        <Button
          onClick={() => workHandler(false)}
          className="buton-start-finish-work"
        >
          Спирам работа на обекта
        </Button>
      )}
    </div>
  );
};

export default DetailsStartFinishWork;
